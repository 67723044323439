import React from 'react';

import { ArrowUpWideNarrow, BarChart2, Megaphone, MessagesSquare, Search } from 'lucide-react';

import GenericLandingHelmet, { type Props as Helmet } from 'common/helmets/GenericLandingHelmet';
import LandingImages from 'common/www/assets/LandingImages';
import TestimonialData from 'common/www/LandingV3/Data/TestimonialData';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingFAQ, { type LandingFAQProps } from 'common/www/LandingV3/LandingFAQ';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero';
import LandingTabsAsLinks, {
  type Props as Tabs,
} from 'common/www/LandingV3/LandingTabs/LandingTabsAsLinks';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/LandingV3/_LandingBugTracking.scss';

const HelmetData: Helmet = {
  title: 'Feedback-driven bug and issue tracking tool',
  description:
    'Easily capture, prioritize and fix bug reports while keeping your users in the loop. Get started for free today. ',
  pageURL: 'https://canny.io/features/enterprise-feedback-management',
};

const HeroSection: Hero = {
  copy: {
    pretitle: 'Issue tracking',
    title: 'Keep users in the loop with a feedback-driven bug tracking tool',
    subtitle:
      'Track and prioritize bug reports wherever users find them. Keep everyone updated as you fix them.',
    registerButton: 'Get started for free',
    cost: 'No credit card required',
  },
};

const ProductCycle: Tabs = {
  copy: {
    title: 'Your complete bug tracking system',
    subtitle: 'Listen to your customers and action their feedback',
  },
  tabs: [
    {
      title: 'Feedback discovery',
      body: 'Let users report bugs and track them in your feedback board ',
      icon: MessagesSquare,
      url: '/features/autopilot',
    },
    {
      title: 'Analyze feedback',
      body: "Understand each bug's severity and impact on different users",
      icon: Search,
      url: '/features/analyze-feedback',
    },
    {
      title: 'Prioritize requests',
      body: "Prioritize and plan which bugs you'll fix and share status updates",
      icon: ArrowUpWideNarrow,
      url: '/features/product-roadmap',
    },
    {
      title: 'Build roadmaps',
      body: "Create roadmaps to show customers you're working on their feedback",
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      url: '/use-cases/public-roadmap',
    },
    {
      title: 'Share updates',
      body: 'Announce bug resolution and automatically share details with reporters',
      icon: Megaphone,
      url: '/features/product-changelog',
    },
  ],
};

const EDFContentBlocks: Block[] = [
  {
    copy: {
      title: "Capture bugs wherever they're reported",
      subtitle: 'Let users easily share bug reports on your site or via customer support',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Keep users updated on bug reports',
      subtitle:
        'Create a public bug tracking board and roadmap - let customers know the status of bugs they care about',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Sync bug reports with your project management tool',
      subtitle:
        'Push new bug reports to your tool. Update Canny posts as your team works on issues.',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Works for internal bug tracking and testing',
      subtitle: 'Use our bug tracking tools privately or control which groups can access them',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
];

const BugTrackingTestimonials: Testimonials = {
  copy: {
    title: 'Companies like yours count on Canny',
    subtitle:
      "Don't take our word for it. Teams like yours save time and understand their customers better with Canny.",
  },
  categorizedTestimonials: {
    general: [TestimonialData.Pallyy, TestimonialData.Strapi, TestimonialData.AgencyAnalytics],
  },
};

const FAQ: LandingFAQProps = {
  faqs: [
    {
      question: 'Can Canny help with more than bug tracking? ',
      answer: [
        'We offer a range of product management tools covering feedback management, prioritization, roadmapping, and release notes. Canny is a tool that helps you close the feedback loop. This process supports bug reporting and resolution well. ',
      ],
    },
    {
      question: 'How much does it cost?',
      answer: [
        "You can set up a free plan to start. It's fully functional free bug tracking software. Some of our more powerful features are on our paid plans which start at $79 a month.",
      ],
    },
    {
      question: 'Will everyone see all our bugs?',
      answer: [
        "It's up to you. You can make your feedback board and roadmap public. Or, you can restrict it to specific users based on filters you set in your account. You can also restrict boards to just your internal teams. This can be really helpful when your software development team is working on early versions of features.",
      ],
    },
    {
      question: 'What tools do you integrate with?',
      answer: [
        'We connect to a wide range of tools to support your workflow.',
        'That includes:',
        [
          'Project management tools like Jira, GitHub, ClickUp, Linear, Azure DevOps, and Asana',
          'Customer communication tools like Intercom, Zendesk, Salesforce, HubSpot, and Microsoft Teams',
          'Authentication tools like G Suite, Okta, OpenID, and OneLogin',
          'Automation tools like Zapier and Make',
        ],
      ],
    },
  ],
};

const cta: CTA = {
  copy: {
    title: 'Ready to get started?',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
};

const LandingBugTracking = () => {
  return (
    <>
      <GenericLandingHelmet {...HelmetData} />
      <main className="LandingBugTracking" id="main">
        <LandingHero {...HeroSection} />
        {EDFContentBlocks.map((block, index) => {
          return (
            <LandingContentBlock
              flip={index % 2 === 1}
              {...block}
              key={`Autopilot__content-block__${index}`}
            />
          );
        })}
        <LandingTestimonials centerHeading {...BugTrackingTestimonials} />
        <LandingFAQ {...FAQ} />
        <LandingTabsAsLinks {...ProductCycle} />
        <LandingCTA className="LandingBugTracking__cta" {...cta} />
      </main>
    </>
  );
};

export default LandingBugTracking;
