import React from 'react';

import GenericLandingHelmet, { type Props as Helmet } from 'common/helmets/GenericLandingHelmet';
import LandingImages from 'common/www/assets/LandingImages';
import LandingFeaturePageLinks from 'common/www/LandingV3/common/LandingFeaturePageLinks';
import IntegrationData from 'common/www/LandingV3/Data/IntegrationData';
import TestimonialData from 'common/www/LandingV3/Data/TestimonialData';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingFAQ, { type LandingFAQProps } from 'common/www/LandingV3/LandingFAQ';
import LandingHero, { type Props as Hero } from 'common/www/LandingV3/LandingHero';
import LandingIntegrations, {
  type Props as Integrations,
} from 'common/www/LandingV3/LandingIntegrations';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/LandingV3/_LandingEnterpriseFeedbackManagement.scss';

const HelmetData: Helmet = {
  title: 'Enterprise feedback management software',
  description:
    'Enjoy industry-leading features, reliability, security, and support. Uncover customer insights at any scale.',
  pageURL: 'https://canny.io/features/enterprise-feedback-management',
};

const HeroSection: Hero = {
  copy: {
    pretitle: 'Feedback management',
    title: 'Build better products with enterprise feedback management software',
    subtitle:
      'Enjoy industry-leading features, reliability, security, and support. Uncover customer insights at any scale.',
    registerButton: 'Get started for free',
    cost: 'No credit card required',
  },
};

const EFMTestimonials: Testimonials = {
  copy: {
    title: 'Companies like yours count on Canny',
    subtitle:
      "Don't take our word for it. Teams like yours save time and understand their customers better with Canny.",
  },
  categorizedTestimonials: {
    general: [TestimonialData.ClickUp, TestimonialData.Mercury, TestimonialData.Axios],
  },
};

const EFMContentBlocks: Block[] = [
  {
    copy: {
      title: 'Centralize your teams and feedback data in one place',
      subtitle: 'Let your teams easily access all your feedback to make better product decisions',
    },
    image: {
      src: LandingImages.collect,
      alt: '',
    },
  },
  {
    copy: {
      title: "Let Canny's Autopilot do the heavy lifting for you",
      subtitle:
        'AI-powered features handle the manual work of managing feedback so you can focus on building your product',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Uncover revenue-driving features',
      subtitle: 'Cut through the feedback noise to find out what key customers want',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Deliver an amazing customer experience',
      subtitle: 'Boost customer satisfaction by listening to and acting on feedback',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Security you can count on',
      subtitle: "Industry-leading security and compliance you won't find with smaller vendors.",
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Award-winning customer support',
      subtitle: 'Our dedicated customer success team helps you make the most of your investment.',
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
  {
    copy: {
      title: 'Flexible account setup and billing',
      subtitle: "Canny's Enterprise plan is designed to support the needs of enterprise companies",
    },
    image: {
      src: LandingImages.autopilot1_full,
      alt: '',
    },
  },
];

const EFMIntegrations: Integrations = {
  copy: {
    title: 'Works well with your existing workflow',
    subtitle:
      'Canny integrates with tools you already use for customer service, project management, and more',
    seeAllLink: 'See all integrations',
  },
  integrations: [
    [
      IntegrationData.Salesforce,
      IntegrationData.Hubspot,
      IntegrationData.Azure,
      IntegrationData.Okta,
    ],
  ],
};

const FAQ: LandingFAQProps = {
  faqs: [
    {
      question: 'Is our customer data secure?',
      answer: [
        "Yes, Canny offers industry-leading security and can provide a SOC2 Type 2 report. Most smaller EFM Software providers don't have this level of security and compliance.",
      ],
    },
    {
      question: 'What tools does Canny offer?',
      answer: [
        'Canny offers a full suite of tools designed to help you manage customer feedback. They cover feedback collection, roadmaps, prioritization, and product updates.',
        {
          node: (
            <>
              <p>
                We also{' '}
                <a href="https://canny.io/integrations" rel="noopener" target="_blank">
                  integrate
                </a>{' '}
                with other best in class tools so you can use the best tools on the market for all
                your needs. That includes project management tools, CRMs, customer support,
                analytics, and more.
              </p>
            </>
          ),
          text: 'We also <a href="https://canny.io/integrations" rel="noopener" target="_blank">integrate</a> with other best in class tools so you can use the best tools on the market for all your needs. That includes project management tools, CRMs, customer support, analytics, and more.',
        },
        'Canny can pull customer survey data from your CRM or bulk import it. That includes survey results, net promoter score (NPS) data, customer effort score (CES), etc. You can create custom fields in Canny for these, and use them to analyze feedback.',
      ],
    },
    {
      question: 'What are the benefits of the Hubspot and Salesforce integrations?',
      answer: [
        'These help your product team understand what features they should be building to unlock more revenue for your company. You can connect deals in Hubspot and Salesforce to feedback in Canny. This lets you track the revenue impact of customer feedback.',
        'You can also sync custom fields like ARR that can be used as an impact factor when prioritizing requests.',
      ],
    },
    {
      question: 'Do you offer an API?',
      answer: [
        'Yes, our open API is available to all customers. We also offer integration support to enterprise clients from our support engineering team. They help customize Canny to make sure it supports your workflow.',
      ],
    },
    {
      question: 'How does Canny help with customer experience management?',
      answer: [
        "We believe that you can build a better product by listening to customer feedback. Our tools are designed to help you manage the entire customer feedback loop — listening, analyzing, prioritizing, building, and then announcing. Keeping your customers informed as you act on their feedback boosts customer loyalty and retention. It also helps with customer engagement as you invite them to be a part of your product's development. Feeling heard and involved is a major driver of customer experience. ",
      ],
    },
  ],
};

const cta: CTA = {
  copy: {
    title: 'Ready to get started?',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
};

const LandingEnterpriseFeedbackManagement = () => {
  return (
    <>
      <GenericLandingHelmet {...HelmetData} />
      <main className="LandingEnterpriseFeedbackManagement" id="main">
        <LandingHero {...HeroSection} />
        <LandingFeaturePageLinks
          title="Your complete enterprise feedback management system"
          subtitle="Listen to your customers and action their feedback"
        />
        {EFMContentBlocks.map((block, index) => {
          return (
            <LandingContentBlock
              flip={index % 2 === 1}
              {...block}
              key={`Autopilot__content-block__${index}`}
            />
          );
        })}
        <LandingIntegrations {...EFMIntegrations} />
        <LandingTestimonials centerHeading {...EFMTestimonials} />
        <LandingFAQ {...FAQ} />
        <LandingCTA {...cta} />
      </main>
    </>
  );
};

export default LandingEnterpriseFeedbackManagement;
