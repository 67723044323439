import React from 'react';

import classnames from 'classnames';
import { ArrowRight } from 'lucide-react';

import 'css/components/www/LandingV3/_LandingCTA.scss';

import Link from 'common/Link';
import { demoLink, registerLink } from 'common/routeComponents/landing/core/constants';
import nbspLastSpace from 'common/util/nbspLastSpace';

type Copy = {
  title: string;
  subtitle?: string;
  demoButton: string;
  registerButton: string;
};

export type Props = {
  copy: Copy;
  registerLinkOverride?: string;
  demoLinkOverride?: string;
  orientation?: 'vertical' | 'horizontal';
  className?: string;
};

const LandingCTA = ({
  copy: { title, subtitle, demoButton, registerButton },
  registerLinkOverride = registerLink,
  demoLinkOverride = demoLink,
  orientation = 'horizontal',
  className,
}: Props) => {
  return (
    <section className={classnames('LandingCTA', `LandingCTA--${orientation}`, className)}>
      <h2 className="LandingCTA__title">{nbspLastSpace(title)}</h2>
      {subtitle ? <p className="LandingCTA__subtitle">{nbspLastSpace(subtitle)}</p> : null}
      <div className="LandingCTA__links">
        <Link to={demoLinkOverride} className="LandingCTA__button  LandingCTA__button--outlined">
          {demoButton}
        </Link>
        <Link to={registerLinkOverride} className="LandingCTA__button">
          {registerButton}
          <ArrowRight size={20} strokeWidth={1.5} />
        </Link>
      </div>
    </section>
  );
};

export default LandingCTA;
