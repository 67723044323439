import {
  AgencyAnalyticsLogo,
  AryeoLogo,
  AxiosLogo,
  CircleCILogo,
  ClickUpLogo,
  CreditRepairCloudLogo,
  Document360Logo,
  FiguredLogo,
  GiveButterLogo,
  HiveLogo,
  JaneLogo,
  KnakLogo,
  MercuryLogo,
  MissiveLogo,
  OrcaScanLogo,
  PallyyLogo,
  StellarWPLogo,
  StrapiLogo,
} from 'common/www/assets/CompanyLogos';
import {
  AurelienGeorget,
  ChelseaDarby,
  FelixHiggs,
  IdaStrom,
  JayPatel,
  JesseSandala,
  KeenanJones,
  LiyaAi,
  MarcusMoretti,
  MattCromwell,
  MichaelaRollings,
  NickBarraclough,
  OwenDoherty,
  PhilippeLehoux,
  RachelSchaaf,
  SaravanaKumar,
  TimBennetto,
  ZebEvans,
} from 'common/www/assets/Headshots';
import { type Props as Testimonial } from 'common/www/LandingV3/LandingTestimonialCard';

export const OrcaScanTestimonial: Testimonial = {
  linkUrl: '/case-studies/',
  logo: {
    alt: 'Orca Scan',
    src: OrcaScanLogo,
    height: 32,
    width: 146,
  },
  body: "Canny's Autopilot ensures feature requests never fall through the cracks. We've seen an 80% increase in requests logged since introducing Autopilot.",
  person: {
    imageUrl: OwenDoherty,
    name: 'Owen Doherty',
    title: 'COO',
  },
};

const Pallyy: Testimonial = {
  linkUrl: '/case-studies/pallyy',
  logo: {
    alt: 'Pallyy',
    src: PallyyLogo,
    height: 32,
    width: 98,
  },
  body: 'The feedback started coming in on auto-pilot. No more sending cold emails - it was just a stream of incoming tickets. This helped guide Pallyy in the early days and get us to where we are today.',
  person: {
    imageUrl: TimBennetto,
    name: 'Tim Bennetto',
    title: 'Founder',
  },
};

export const CreditRepairCloud: Testimonial = {
  linkUrl: '/case-studies/',
  logo: {
    alt: 'Credit Repair Cloud',
    src: CreditRepairCloudLogo,
    height: 32,
    width: 150,
  },
  body: "I LOVE how it auto-scans our support tickets and magically finds feedback. We've been able to 10x our feedback & remove many duplicate posts.",
  person: {
    imageUrl: KeenanJones,
    name: 'Keenan Jones',
    title: 'VP Product',
  },
};

export const StellarWP: Testimonial = {
  linkUrl: '/case-studies/',
  logo: {
    alt: 'Stellar WP',
    src: StellarWPLogo,
    height: 32,
    width: 157,
  },
  body: "We're seeing hundreds of support tickets turned into actionable insights both unique and as votes on existing posts with very high accuracy.",
  person: {
    imageUrl: MattCromwell,
    name: 'Matt Cromwell',
    title: 'Senior Director, CX',
  },
};

export const CircleCI = {
  linkUrl: '/case-studies/circleci',
  logo: {
    alt: 'Circle CI',
    src: CircleCILogo,
    height: 32,
    width: 153,
  },
  body: 'It just makes our lives easier from the product perspective, to be able to go to this one tool and see everything.',
  person: {
    imageUrl: LiyaAi,
    name: 'Liya Ai',
    title: 'Product Operations Manager',
  },
};

export const AgencyAnalytics = {
  linkUrl: '/case-studies/agencyanalytics',
  logo: {
    alt: 'Agency Analytics',
    src: AgencyAnalyticsLogo,
    height: 32,
    width: 218,
  },
  body: 'Thanks to Canny, we find the issues that are most requested, avoid churn, and generate more revenue.',
  person: {
    imageUrl: JayPatel,
    name: 'Jay Patel',
    title: 'Product Manager',
  },
};

export const Mercury = {
  linkUrl: '/case-studies/mercury',
  logo: {
    alt: 'Mercury',
    src: MercuryLogo,
    height: 32,
    width: 173,
  },
  body: "We use Canny as a way to distribute product thinking across the team. Now that everything is in one place, it's so much easier and more efficient.",
  person: {
    imageUrl: IdaStrom,
    name: 'Ida Ström',
    title: 'Senior Product Designer',
  },
};

export const Aryeo = {
  linkUrl: '/case-studies/aryeo',
  logo: {
    alt: 'Aryeo',
    src: AryeoLogo,
    height: 32,
    width: 117,
  },
  body: 'Canny has reduced the number of inbounds in our support inbox by 20%, which is a big deal.',
  person: {
    imageUrl: ChelseaDarby,
    name: 'Chelsea Darby',
    title: 'Customer Success',
  },
};

export const Figured = {
  linkUrl: '/case-studies/figured',
  logo: {
    alt: 'Figured',
    src: FiguredLogo,
    height: 32,
    width: 92,
  },
  body: 'Suddenly, everything from Canny to Intercom to Slack was linked. We were actually able to record what people wanted in one place, with basically no effort.',
  person: {
    imageUrl: NickBarraclough,
    name: 'Nick Barraclough',
    title: 'Partner Success Manager',
  },
};

export const Knak = {
  linkUrl: '/case-studies/knak',
  logo: {
    alt: 'Knak',
    src: KnakLogo,
    height: 32,
    width: 104,
  },
  body: "Gathering and analyzing feedback is so much more quick and efficient now, and it's saving us a lot of time.",
  person: {
    imageUrl: FelixHiggs,
    name: 'Felix Higgs',
    title: 'Senior CSM',
  },
};

export const Strapi = {
  linkUrl: '/case-studies/strapi',
  logo: {
    alt: 'Strapi',
    src: StrapiLogo,
    height: 32,
    width: 134,
  },
  body: "Canny even helps us win deals. When a prospect requests a feature, it's easy for the sales team to log it in and follow up once it's shipped.",
  person: {
    imageUrl: AurelienGeorget,
    name: 'Aurélien Georget',
    title: 'Chief Product Officer',
  },
};

export const Jane = {
  linkUrl: '/case-studies/janetechnologies',
  logo: {
    alt: 'Jane',
    src: JaneLogo,
    height: 32,
    width: 121,
  },
  body: 'When our sales reps are talking to a prospect, they often hear a request for a very specific feature. Now, they can quickly log that in Canny and mention how important it is.',
  person: {
    imageUrl: RachelSchaaf,
    name: 'Rachel Schaaf',
    title: 'Senior Director',
  },
};

export const GiveButter = {
  linkUrl: '/case-studies/givebutter',
  logo: {
    alt: 'GiveButter',
    src: GiveButterLogo,
    height: 32,
    width: 208,
  },
  body: 'As the sales team is working with the prospect, they use Canny to add new requests and upvote existing ones on behalf of those prospects. That really signals to us that we should prioritize certain features.',
  person: {
    imageUrl: JesseSandala,
    name: 'Jesse Sandala',
    title: 'Director of Product',
  },
};

export const Hive = {
  linkUrl: '/case-studies/hive',
  logo: {
    alt: 'Hive',
    src: HiveLogo,
    height: 32,
    width: 136,
  },
  body: "Canny is saving us about 2-3 hours per week on product marketing. Instead of going back and forth between various tools, everything lives in Canny now. It's our one source of truth.",
  person: {
    imageUrl: MichaelaRollings,
    name: 'Michaela Rollings',
    title: 'Head of Brand & Content',
  },
};

export const Axios = {
  linkUrl: '/case-studies/axios',
  logo: {
    alt: 'Axios',
    src: AxiosLogo,
    height: 32,
    width: 126,
  },
  body: 'Canny really helped us to achieve product-market fit for the app during the beta period.',
  person: {
    imageUrl: MarcusMoretti,
    name: 'Marcus Moretti',
    title: 'Senior Product Director',
  },
};

export const ClickUp = {
  linkUrl: '/case-studies/clickup',
  logo: {
    alt: 'Click Up',
    src: ClickUpLogo,
    height: 32,
    width: 131,
  },
  body: "Canny removes our need for user testing entirely, which is something I didn't really anticipate.",
  person: {
    imageUrl: ZebEvans,
    name: 'Zeb Evans',
    title: 'CEO',
  },
};

export const Missive = {
  linkUrl: '/case-studies/missive',
  logo: {
    alt: 'Missive',
    src: MissiveLogo,
    height: 32,
    width: 182,
  },
  body: "Canny really helps us not just with feedback management, but also with decision-making. It pushes us to be more creative with what we're building next. Because, Canny helps us balance user ideas with our own input.",
  person: {
    imageUrl: PhilippeLehoux,
    name: 'Philippe Lehoux',
    title: 'CEO',
  },
};

export const Document360 = {
  linkUrl: '/case-studies/document360',
  logo: {
    alt: 'Document360',
    src: Document360Logo,
    height: 32,
    width: 242,
  },
  body: "Canny came out on top for two reasons: it's very simple to use, and our users love it.",
  person: {
    imageUrl: SaravanaKumar,
    name: 'Saravana Kumar',
    title: 'Founder',
  },
};

export default {
  Document360,
  Missive,
  ClickUp,
  GiveButter,
  Jane,
  CircleCI,
  Hive,
  Strapi,
  Axios,
  Pallyy,
  Knak,
  Figured,
  Aryeo,
  Mercury,
  AgencyAnalytics,
  OrcaScan: OrcaScanTestimonial,
  CreditRepairCloud,
  StellarWP,
};
